<template>
	<div>
		<div id="scrollbar">
            <div id="scrollbar-bg"></div>
        </div>
		<header class="header_white">
			<Navbar logoColor="true"></Navbar>
		</header>
		<section id="about" class="bg_lighgrey_pattern">
			<div id="post" class="container">
				<div class="row">
					<div class="col-12 col-lg-10 offset-lg-1 aos-init" data-aos="fade-up" data-aos-duration="1000">
            			<img class="img-fluid img--animated-soft" :src="require(`@/assets/blog/m2l.png`)" alt="blog" height="" width="">
						<h3 class="title_xl title_lightblue mt-4 mb-3">
							M2L 2025: Postanite deo globalne AI zajednice!

						</h3>

            <div class="text_bold text_m mt-4 mb-4">
              <div class="row px-3">
                <div class="d-inline-block mr-3">
                  <img class="rounded-circle" :src="require(`@/assets/blog/milica.jpeg`)" width="40" height="40">
                </div>
                <div class="d-inline-block">
                  Milica Brković<br>
                  Mar 21, 2025
                </div>
              </div>
            </div>
						<div class="reading-time badge badge-danger d-inline-block px-2">
							<details>
							  <summary>1 minut</summary><span></span>
							</details>
						</div>
            <hr/>
						<p class="text_xl  my-3">
							Mediteranska letnja škola mašinskog učenja (M2L) ponovo okuplja talentovane istraživače i profesionalce iz oblasti veštačke inteligencije. M2L 2025 će se održati od 8. do 12. septembra na Fakultetu elektrotehnike, strojarstva i brodogradnje (FESB) u Splitu, Hrvatska.
							<br><br>
							Ova renomirana letnja škola pruža učesnicima priliku da unaprede svoje znanje kroz predavanja vodećih stručnjaka iz oblasti AI i praktične radionice namenjene primeni naučenog na stvarnim problemima.
							<br><br>
							Učešće je potpuno besplatno za studente, dok profesionalci iz akademskih i industrijskih krugova mogu da prisustvuju uz simboličnu kotizaciju. Dostupan je i ograničen broj stipendija za one kojima je potrebna finansijska podrška. Rok za prijavu je 28. mart 2025. godine, dok će rezultati selekcije biti objavljeni početkom maja 2025. godine.
							<br><br>
							Tim povodom razgovarali smo sa članovima organizacionog tima M2L iz kompanije Google DeepMind dr Nemanjom Rakićevićem i dr Matkom Bošnjakom. Ovi naučnici nam otkrivaju šta učesnici mogu da očekuju, kako M2L doprinosi razvoju AI zajednice u regionu Mediterana i zašto je ovo iskustvo dragocena prilika za sve koji žele da unaprede svoje znanje i prošire svoju istraživačku mrežu.

						</p>
						<h3 class="title_m title_lightblue my-4 my-lg-5">
							1. Iz Vašeg iskustva, koji su najsvrsishodniji načini za podsticanje dugoročne saradnje između učesnika M2L i nakon samog događaja? Kako učesnici M2L mogu aktivno da doprinesu jačanju zajednice nakon događaja?
						</h3>
						<h4 class="title_xs title_lightblue">
							[Dr Nemanja Rakićević, Google DeepMind]
						</h4>
						<p class="text_xl  my-3">
							Misija Mediteranske letnje škole mašinskog učenja je da tokom svog trajanja poveže mlade eksperte, odnosno buduće lidere iz oblasti AI koji su trenutno na doktorskim studijama ili rade u razvojnim centrima u industriji. Učesnici slušaju predavanja vodećih eksperata iz različitih oblasti AI, a potom to znanje primenjuju tokom praktičnih tutorijala. Skoro svakog dana postoji mnoštvo prilika za formalno i neformalno povezivanje i druženje, tokom posterskih sesija, ručkova i večera, kao i nekoliko organizovanih događaja za socijalizaciju.
							<br><br>
							Tokom same škole, učesnici imaju mnoštvo prilika za povezivanje sa kolegama, predavačima i tutorima i to veoma dobro koriste, što ima pozitivan uticaj na formiranje njihovog daljeg istraživačkog rada i karijernog puta.
							<br><br>
							Prirodno je da se ovakve veze nastave i nakon trajanja škole, kako individualno među učesnicima određene generacije, tako i kroz kreiranje M2L alumni mreže. Do sada je bilo nekoliko događaja organizovanih za M2L alumni van konteksta škole, gde su učesnici mogli dalje da razmenjuju iskustva u neformalnoj atmosferi.
							<br><br>
							Mi kao organizatori planiramo da u budućnosti još više pažnje posvetimo izgradnji alumni zajednice oko M2L, ne samo kroz neformalna druženja, već i kroz manje samostalne naučne konferencije fokusirane na region Mediterana.
							<br><br>
							Takođe, da bi zajednica bila uspešna, neophodno je da i studenti budu proaktivni oko njene izgradnje, jer i nakon završetka škole, učesnici postaju deo i M2L i lokalne zajednice kojoj pripadaju. Na njima je da dalje šire svoje znanje i iskustvo i na taj način daju doprinos svojoj lokalnoj istraživačkoj zajednici.
							<br><br>
							Ovo mogu raditi kroz organizovanje lokalnih događaja koje se nadamo da ćemo u budućnosti, kao M2L, moći da podržavamo više.

						</p>
						<h3 class="title_m title_lightblue my-4 my-lg-5">
							2. Koji savet imate za ovogodišnje učesnike kako bi maksimalno iskoristili svoje M2L iskustvo i to ne samo tokom tehničkih sesija, već za sve vreme tokom trajanja škole?

						</h3>
						<h4 class="title_xs title_lightblue">
							[Dr Nemanja Rakićević, Google DeepMind]
						</h4>
						<p class="text_xl  my-3">
							Događanja tokom škole mogu delovati kao previše informacija za učesnike, jer se mnogo toga dešava u kratkom vremenskom roku, i potrebno je neko vreme da se sve to isprocesuira.
							<br><br>
							Moja preporuka učesnicima je da budu otvorenih shvatanja i da se ne preopterećuju ukoliko ne postignu sve što su želeli tokom škole, u smislu razumevanja materijala. Glavni cilj škole, pored edukacije, jeste direktna interakcija i povezivanje sa predavačima, tutorima i kolegama, i to je nešto što se može ostvariti samo uživo.
							<br><br>
							Svi materijali, kao što su snimci predavanja i sadržaj vežbi, postaju dostupni onlajn nakon škole, tako da ih učesnici mogu pratiti u svom tempu.
							<br><br>
							Takođe, preporučujem da se osećate slobodno da se povežete sa što više kolega tokom škole i upoznate sa njihovim radom, jer su upravo ta poznanstva ono što je neophodno za uspešan naučno-istraživački put.

						</p>
						<h4 class="title_xs title_lightblue">
							[Dr Matko Bošnjak, Google DeepMind]

						</h4>
						<p class="text_xl  my-3">
							Budite promišljeni i strateški. Mjesecima prije događaja razmislite o svojim ciljevima: što želite postići?
							<br><br>
							- Želite li produbiti svoje razumijevanje određene oblasti? - Prioritirajte predavanja i tutorijale, kao i sudionike koji rade u toj oblasti.
							<br><br>
							- Želite li istražiti potencijalne smjerove istraživanja? - Aktivno tražite sudionike i predavače čiji rad se poklapa s vašim interesima.
							<br><br>
							- Želite li umrežiti buduće suradnike? - Uključite se u rasprave već na početku, podijelite svoje ideje tijekom posterske sesije, budite otvoreni za povratne informacije.
							<br><br>
							- Želite li tražiti karijerne prilike? - Istražite kompanije koje sponzoriraju događaj i razgovarajte s njihovim predstavnicima.
							<br><br>
							- Želite li se informirati o svom sljedećem profesionalnom koraku, bilo u akademiji ili industriji? - Razgovarajte s ljudima koji se nalaze na poziciji na kojoj biste željeli biti.
							<br><br>
							Budite promišljeni, uključite se i povežite se — maksimalno iskoristite M2L.
						</p>
						<h3 class="title_m title_lightblue my-4 my-lg-5">
							3.  Ako postoji jedna ključna lekcija ili iskustvo koje želite da svaki učesnik M2L-a ponese sa sobom, šta bi to bilo?


						</h3>
						<h4 class="title_xs title_lightblue">
							[Dr Nemanja Rakićević, Google DeepMind]
						</h4>
						<p class="text_xl  my-3">
							Imao sam priliku da iskusim letnju školu iz nekoliko perspektiva - kao učesnik, tutor i organizator. Iz mog iskustva, učesnici treba da posmatraju ovu školu kao priliku za “istraživanje o istraživanju”, odnosno da saznaju više o različitim oblastima kojima se drugi učesnici, predavači i tutori bave.
							<br><br>
							U današnjem svetu, novi trendovi i otkrića u AI se gotovo svakodnevno dešavaju i M2L se trudi da napravi najbolji presek trenutnog stanja u oblasti kroz predavanja i vežbe, ali i kroz selekciju učesnika koji ravnomerno predstavljaju različite istraživačke pravce u oblasti AI.

						</p>
						<h4 class="title_xs title_lightblue">
							[Dr Matko Bošnjak, Google DeepMind]

						</h4>
						<p class="text_xl  my-3">
							Njegujte prilagodljivost kroz smislene veze. U stalno promjenjivom svijetu ML-a i AI-a, cjeloživotno učenje je nužno.
							<br><br>
							Veze koje gradite na događajima poput M2L-a, ali i izvan njih, mogu vam otvoriti nova znanja, prilike za suradnju, prijateljstva i, što je najvažnije, mogu vam pomoći da odredite svoje sljedeće korake. One vas mogu usmjeriti prema vašim ciljevima i udaljiti vas od nepotrebnih zaobilaznih puteva. Te veze zaista mogu promijeniti vaš život, kao što sam i sam iskusio.

						</p>
					</div>

				</div>




			</div>
		</section>
		<Footer bg="footer_bg-red"></Footer>
	</div>
</template>


<script>
import Navbar from "@/components/layout/Navbar"
import Footer from "@/components/layout/Footer"

export default{
	name: "M2L2025",

	components: {
		Navbar,
		Footer
	},
	methods: {
		getWordCount(){
			const post = document.getElementById("post");
			return post.innerText.match(/\w+/g).length;
		},

		setReadingTime(){
			const avgWordsPerMin = 250;
			const readingTimeSummary = document.querySelector(".reading-time");

			let count = this.getWordCount();
			let time = Math.ceil(count / avgWordsPerMin);

			readingTimeSummary.innerText = time + " min čitanja";
		}
	},

  	mounted() {

		this.setReadingTime();

      	var bar_bg = document.getElementById("scrollbar-bg"),
			body = document.body,
			html = document.documentElement;

		bar_bg.style.minWidth = document.width + "px";

		document.getElementsByTagName("body")[0].onresize = function() {
			// Update the gradient width
			bar_bg.style.minWidth = document.width + "px";
		}

		window.onscroll = function() {
			// Change the width of the progress bar
			var bar = document.getElementById("scrollbar"),
				dw  = document.documentElement.clientWidth,
				dh  = Math.max( body.scrollHeight, body.offsetHeight,
							html.clientHeight, html.scrollHeight, html.offsetHeight ),
				wh  = window.innerHeight,
				pos = pageYOffset || (document.documentElement.clientHeight ?
								document.documentElement.scrollTop : document.body.scrollTop),
				bw  = ((pos / (dh - wh)) * 100);

			bar.style.width = bw + "%";
		}
  	}
}

</script>
